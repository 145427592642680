body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  min-height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f9fafc;
  overflow: auto;
}

body,
#root {
  width: 100%;
  min-height: fit-content;
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Inter', 'Sofia Pro';
  background-color: #f9fafc;
}

@media (min-width: 767px) {
  body {
    width: 767px;
    box-shadow: -1px -1px 5px 5px rgb(158, 157, 157);
    min-height: fit-content !important;
    margin: 0px;
    padding: 0px;
    overflow: auto;
  }

  html {
    min-height: fit-content;
    display: flex;
    justify-content: center;
    background: lightgrey;
    overflow: auto;
  }
}

/* Tablet styles */
@media screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }
}

/* Desktop styles */
@media screen and (min-width: 1024px) {
  body {
    font-size: 20px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

#root {
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
